// document.addEventListener("keyup", function (e) {
//   if (e.keyCode === 71) {
//     document.querySelector(".grid-overlay").classList.toggle("show");
//   }
// });

const videoControls = document.querySelectorAll(".video-block .controls .button");
Array.from(videoControls).forEach(function (element) {
  element.addEventListener("click", function (e) {
    let videoBlock = element.parentNode.parentNode;
    let video = videoBlock.querySelector("iframe");
    var player = new Vimeo.Player(video);

    if (!videoBlock.classList.contains("playing")) {
      videoBlock.classList.add("playing");
      player.play();
    }

    player.on("pause", function () {
      videoBlock.classList.remove("playing");
    });

    e.preventDefault();
    return false;
  });
});

async function fetchLogos() {
  const logos = await DatoCMS.getAllLogos();
  // console.log(logos);
  logos.forEach((logo) => {
    let logoHtml = `
    <a href="brands.html" class="logo-block">
      <h5>${logo.brandName}</h5>
      <p>${logo.category}</p>
      <div class="logo-block-icon">
        <img src="${logo.logotype.url}" alt="Vogue" />
      </div>
      <div class="explore-more">
        <img src="become-a-partner.svg" alt="" />
      </div>
    </a>
    `;
    logoGrid.innerHTML += logoHtml;
  });
}

const logoGrid = document.querySelector(".logo-grid");
if (logoGrid) {
  fetchLogos();
}

const logoBlocks = document.querySelectorAll(".logo-block");
Array.from(logoBlocks).forEach(function (element) {
  element.addEventListener("mousemove", function (e) {
    let circle = element.querySelector(".explore-more");
    let bounds = element.getBoundingClientRect();
    let x = e.clientX - bounds.left;
    let y = e.clientY - bounds.top;
    circle.style.left = Math.round(x) + "px";
    circle.style.top = Math.round(y) + "px";
  });
});

async function fetchTestimonials(slider) {
  const testimonials = await DatoCMS.getAllTestimonials();
  testimonials.forEach((testimonial) => {
    let quoteMedia;
    if (testimonial.photo !== null) {
      quoteMedia = `
      <div class="col col-5">
        <div class="media-block natural-height" style="background-color: #d6d6d6">
          <img src="${testimonial.photo.url}" alt="Testimonial Image" />
        </div>
      </div>
      `;
    } else {
      quoteMedia = `
      <div class="col col-3 offset-9">
      <div class="testimonial-spinner">
        <img src="testimonial-spinner.svg" alt="" />
      </div>
      </div>
      `;
    }
    let testimonialHtml = `
    <div class="testimonial">
      <div class="columns">
        <div class="col col-7">
          <p class="testimonial-text">${testimonial.quote}</p>
        </div>
        ${quoteMedia}
        <div class="col testimonial-footer">
          <p><span class="color-secondary-60">${testimonial.company}</span><br />${testimonial.name}<br />${testimonial.role}</p>
        </div>
      </div>
    </div>
    `;
    slider.innerHTML += testimonialHtml;
  });
  
  // @removed on 2023-01 for germany version
  // initSlider(slider);
}

function initSlider(slider) {
  let duration = 12;
  let interval = 30;
  let percentTime, step, tick;
  let isPaused = false;
  let progressCircle = slider.parentNode.querySelector(".progress-ring .progress-ring__circle");
  let flkty = new Flickity(slider, {
    cellSelector: ".testimonial",
    prevNextButtons: false,
    pageDots: false,
    wrapAround: true,
  });

  flkty.on("change", function (event, index) {
    startProgressbar();
  });

  function startProgressbar() {
    resetProgressbar();
    percentTime = 0;
    isPaused = false;
    tick = window.setInterval(increase, interval);
  }

  function increase() {
    if (!isPaused) {
      step = (duration * 1000) / interval;
      percentTime += 100 / step;
      let value = (107 / 100) * percentTime * -1;
      if (progressCircle) {
        progressCircle.setAttribute("stroke-dashoffset", Math.round(value));
      }
      if (percentTime >= 100) {
        flkty.next();
        startProgressbar();
      }
    }
  }

  function resetProgressbar() {
    if (progressCircle) {
      progressCircle.setAttribute("stroke-dashoffset", "107");
    }
    clearTimeout(tick);
  }

  startProgressbar();
}

const sliders = document.querySelectorAll(".testimonials-slider");
Array.from(sliders).forEach(function (element) {
  fetchTestimonials(element);
});

let totalEmissions = localStorage.getItem("totalEmissions");
if (totalEmissions && document.querySelector(".results-link")) {
  document.querySelector(".results-link").classList.add("show");
}
